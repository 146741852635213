import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

const tg = window.Telegram.WebApp;
if (tg.ready()) console.log('Telegramm WebApp installed')
console.dir(tg?.version)
console.dir(tg?.initDataUnsafe)

export { application, tg }

